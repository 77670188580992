import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const OmLeftArrowFilledIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 48}
      height={height || 48}
      viewBox="0 0 48 48"
      fill="none"
    >
      <circle cx="24" cy="24" r="17" fill={fillStyle || '#0C1D3F'} />
      <path
        d="M22.8367 16L24.1837 17.3571L18.6385 23.0179L33 23.0179L33 24.9821L18.6385 24.9821L24.1837 30.625L22.8367 32L15 24L22.8367 16Z"
        fill={fillStyle || '#FFFFFF'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default OmLeftArrowFilledIcon
